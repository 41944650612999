import { ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { isInvalidbject } from '../util/validators';
import envConfig from '../config/envConfig';
export interface ApolloErrorsProps {
	graphQLErrors?: any;
	networkError?: any;
	operation?: object;
	response?: object;
}
export interface ErrorsProps {
	extensions: object;
	message: string;
	locations: string;
	path: string;
}

const apolloErrors = ({ graphQLErrors = {}, networkError = {} as any }: ApolloErrorsProps) => {
	if (!isInvalidbject(graphQLErrors)) {
		for (let err of graphQLErrors) {
			console.warn(`[GraphQL error]: `, {
				message: err.message,
				...(err.locations && { locations: err.locations }),
				...(err.path && { path: err.path }),
			});
		}
	}
	if (networkError) {
		console.warn(`[Network error]: ${networkError}`);
	}
};

const authMiddleware = new ApolloLink((operation, forward) => {
	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			Authorization: `Bearer ${envConfig.AUTH_TOKEN}`,
		},
	}));

	return forward(operation);
});

export default ApolloLink.from([
	onError(apolloErrors),
	authMiddleware,
	createHttpLink({
		uri: envConfig.BASE_ENDPOINT || '',
	}),
]);
