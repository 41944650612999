import Skeleton from '../components/Skeleton';

function SkeletonHeader() {
	return (
		<header className="-inset-x-0 bg-slate-300">
			<div className="container flex justify-between items-center mx-auto px-2 py-1 md:grid md:grid-cols-header md:py-4 xl:px-0 max-w-6xl xl:max-w-5xl 2xl:max-w-screen-lg">
				<Skeleton className="w-12 m-1 bg-slate-200" height="40px"></Skeleton>
				<div className="flex flex-col items-center justify-around fixed inset-0 overflow-hidden md:flex-row md:justify-between md:h-auto md:relative h-0">
					<div className="hidden md:flex flex-col md:flex-row">
						<Skeleton
							className="my-6 px-2 py-1 no-underline rounded-xl text-4xl text-center md:rounded-xl md:text-lg md:mx-2 md:px-3 md:py-1 md:my-0 w-20 bg-slate-200"
							height="38px"></Skeleton>
						<Skeleton
							className="my-6 px-2 py-1 no-underline rounded-xl text-4xl text-center md:rounded-xl md:text-lg md:mx-2 md:px-3 md:py-1 md:my-0 w-20 bg-slate-200"
							height="38px"></Skeleton>
					</div>
					<div className="items-center hidden md:flex">
						<Skeleton className="w-6 mx-1 p-3 md:p-2 bg-slate-200" height="24px"></Skeleton>
						<Skeleton className="w-6 mx-1 p-3 md:p-2 bg-slate-200" height="24px"></Skeleton>
						<Skeleton className="w-6 mx-1 p-3 md:p-2 bg-slate-200" height="24px"></Skeleton>
					</div>
				</div>
				<div>
					<Skeleton className="w-10 mx-2 block sm:hidden bg-slate-200" height="28px"></Skeleton>
				</div>
			</div>
		</header>
	);
}

export default SkeletonHeader;
