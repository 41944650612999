import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes as Router, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import envConfig from './config/envConfig';
import { GET_POSTS } from './graphQuery/queries';
import SkeletonHeader from './Skeleton/SkeletonHeader';
import SkeletonFooter from './Skeleton/SkeletonFooter';
import SkeletonLanding from './Skeleton/SkeletonLanding';
import SkeletonBlog from './Skeleton/SkeletonBlog';
import SkeletonGuide from './Skeleton/SkeletonGuide';
import Colaboration from './components/Colaboration';
import Coordination from './components/Coordination';
import { PostContext } from './context/PostContext';
import './App.css';

const Header = lazy(() => import('./components/Header'));
const Landing = lazy(() => import('./pages/Landing'));
const Blog = lazy(() => import('./pages/Blog'));
const Post = lazy(() => import('./pages/Post'));
const NotFound = lazy(() => import('./pages/NotFound'));
const AvisoPrivacidad = lazy(() => import('./pages/AvisoPrivacidad'));
const GuiaGestionComunicacion = lazy(() => import('./pages/GuiaGestionComunicacion'));
const Cities = lazy(() => import('./pages/Cities'));
const CityPost = lazy(() => import('./pages/CityPost'));
const ReporteFinal = lazy(() => import('./pages/ReporteFinal'));
const ResultCities = lazy(() => import('./pages/ResultCities'));
const FrequentQuestions = lazy(() => import('./pages/FrequentQuestions'));
const Support = lazy(() => import('./pages/Support'));
const Footer = lazy(() => import('./components/Footer'));

function App() {
	const location = useLocation();
	const { addPostsPreview } = useContext(PostContext);
	const [loadingRoute, setLoadingRoute] = useState<any>();
	const { data } = useQuery(GET_POSTS, { variables: { stage: envConfig.STAGE } });
	const isConsent = getCookieConsentValue('reactiva-user');

	useEffect(() => {
		if (isConsent === 'true') {
			onAcceptCookies();
		}
	}, []);

	useEffect(() => {
		if (data?.posts.length) {
			addPostsPreview(data.posts);
		}

		if (location.pathname === '/') {
			setLoadingRoute(<SkeletonLanding />);
		}
		if (location.pathname === '/guia-de-gestion-y-comunicacion/') {
			setLoadingRoute(<SkeletonGuide />);
		}
		if (!!location.pathname.match(/blog/)) {
			setLoadingRoute(<SkeletonBlog />);
		}
	}, [data, location.pathname]);

	useEffect(() => {
		ReactGA.send(location.pathname);
	}, [location]);

	const onAcceptCookies = () => {
		ReactGA.initialize(String(envConfig.GOOGLE_ANALYTICS_ID));
	};

	return (
		<>
			<Suspense fallback={<SkeletonHeader />}>
				<Header />
			</Suspense>
			<main>
				<Suspense fallback={loadingRoute || null}>
					<Router>
						<Route path="/" element={<Landing />} />
						<Route path="/blog/" element={<Blog />} />
						<Route path="/blog/:slug" element={<Post />} />
						<Route path="/aviso-de-privacidad/" element={<AvisoPrivacidad />} />
						<Route path="/guia-de-gestion-y-comunicacion/" element={<GuiaGestionComunicacion />} />
						<Route path="/resultados/" element={<ReporteFinal />} />
						<Route path="/resultados/mapa-ciudades/" element={<ResultCities />} />
						<Route path="/ciudades/" element={<Cities />} />
						<Route path="/ciudades/testimonio/:slug" element={<CityPost />} />
						<Route path="/preguntas-frecuentes/" element={<FrequentQuestions />} />
						<Route path="/contacto/" element={<Support />} />
						<Route path="*" element={<NotFound />} />
					</Router>
				</Suspense>
			</main>
			<Suspense fallback={<SkeletonFooter />}>
				<Colaboration />
				<Coordination />
				<Footer />
			</Suspense>
			<ToastContainer />
			<CookieConsent
				location="bottom"
				declineButtonText="Cancelar"
				cookieName="reactiva-user"
				buttonText="Aceptar"
				ariaAcceptLabel="Aceptar cookies"
				containerClasses="bg-primaryBlack flex w-full items-center bottom-0 md:rounded-lg md:w-3/6 md:right-4 md:bottom-4 lg:md:w-4/12"
				buttonClasses="bg-primaryYellow text-md uppercase rounded-lg text-primaryBlack m-3 px-3 py-2 md:px-2 md:py-1"
				onAccept={onAcceptCookies}>
				Al hacer clic en "Aceptar", acepta el almacenamiento de cookies en su dispositivo para
				mejorar la navegación, experiencia de usuario y analizar el uso del sitio.
			</CookieConsent>
		</>
	);
}

export default App;
