import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

function Coordination({ isHeader }: { isHeader?: boolean }) {
	const isConsent = getCookieConsentValue('reactiva-user');

	const onGAClick = (which: string) => {
		if (isConsent) {
			ReactGA.event('Logos', {
				action: 'Visit logo page',
				label: which,
			});
		}
	};
	return (
		<div
			id="government-reactiva"
			className="container py-8 max-w-6xl xl:max-w-6xl 2xl:max-w-screen-xl mx-auto px-4 mb-20">
			<p className="mb-2">En coordinación con:</p>
			<div className="max-w-2xl grid grid-flow-row gap-y-10 md:grid-flow-col auto-cols-auto px-4">
				<div className="w-full h-full border-b-0 border-r-0 border-govLight md:border-b-0 md:border-r m-auto">
					<a
						href="https://www.gob.mx"
						rel="noreferrer"
						target="_blank"
						onClick={() => onGAClick('gobierno')}
						className="self-center">
						<img
							src="/assets/logo_gobierno_de_mexico.svg"
							alt="logo gobierno de mexico"
							className="m-auto"
							width="124"
							height="36"
							loading="lazy"
						/>
					</a>
				</div>
				<div className="w-full h-full border-b-0 border-r-0 border-govLight md:border-b-0 md:border-r grid">
					<a
						href="https://www.gob.mx/sedatu"
						rel="noreferrer"
						target="_blank"
						onClick={() => onGAClick('sedatu')}
						className="self-center">
						<img
							src="/assets/logo_sedatu.svg"
							alt="logo sedatu"
							className="m-auto self-center"
							width="228"
							height="17"
							loading="lazy"
						/>
					</a>
				</div>
				<div className="w-full h-full grid">
					<a
						href="https://www.gob.mx/semarnat"
						rel="noreferrer"
						target="_blank"
						onClick={() => onGAClick('semarnat')}
						className="self-center">
						<img
							src="/assets/logo_semarnat.svg"
							alt="logo semarnat"
							className="m-auto self-center"
							width="148"
							height="17"
							loading="lazy"
						/>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Coordination;
