import { createContext, useState } from 'react';
import { PostPreviewProps, PostProviderProps, PostContextState, PostProps } from '../typed/typed';

const defaultPostPreview: PostPreviewProps = {
	posts: [],
	lastPosts: [],
	heroPost: {},
	morePosts: [],
};

const defaultValues: PostContextState = {
	postsPreview: defaultPostPreview,
	addPostsPreview: () => {},
};
export const PostContext = createContext<PostContextState>(defaultValues);

const PostProvider = ({ children }: PostProviderProps) => {
	const [postsPreview, setPostsPreview] = useState<PostPreviewProps>(defaultPostPreview);
	const addPostsPreview = (posts: PostProps[]) => {
		const heroPost = posts[0] || {};
		const lastPosts = posts.slice(0, 2) || [];
		const morePosts = posts.slice(1) || [];
		setPostsPreview({ posts, heroPost, lastPosts, morePosts });
	};

	return (
		<PostContext.Provider value={{ postsPreview, addPostsPreview }}>
			{children}
		</PostContext.Provider>
	);
};

export default PostProvider;
