import Skeleton from '../components/Skeleton';

function SkeletonBlog() {
	return (
		<section>
			<div className="container max-w-6xl xl:max-w-5xl 2xl:max-w-screen-lg mx-auto px-4 relative bg-skeletonContainer">
				<div className="grid grid-cols-2 gap-2 justify-items-center justify-around pt-12 pb-10 md:pt-5 md:w-80 md:justify-items-start md:justify-start md:mb-8">
					<div className="m-4 grid">
						<Skeleton className="mb-2 bg-slate-300" height="18px"></Skeleton>
						<Skeleton className="w-24 m-auto md:w-28 md:m-0 bg-slate-300" height="58px"></Skeleton>
					</div>
					<div className="m-4 grid">
						<Skeleton className="mb-2 bg-slate-300" height="18px"></Skeleton>
						<Skeleton className="w-24 m-auto md:w-28 md:m-0 bg-slate-300" height="28px"></Skeleton>
					</div>
				</div>
				<Skeleton className="w-24 mb-6 bg-slate-300" height="45px"></Skeleton>
				<article className="w-full h-60 md:h-96 lg:h-104 relative rounded-xl bg-slate-300">
					<div className="flex items-center absolute bottom-0 p-3">
						<Skeleton className="w-12 h-12 mr-3 rounded-full bg-slate-200" height="46px"></Skeleton>
						<Skeleton className="w-24 md:w-44 bg-slate-200" height="20px"></Skeleton>
					</div>
				</article>
			</div>
		</section>
	);
}

export default SkeletonBlog;
