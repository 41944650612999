import Skeleton from '../components/Skeleton';

function SkeletonGuide() {
	return (
		<section className="container py-10 max-w-6xl xl:max-w-5xl 2xl:max-w-screen-lg mx-auto px-4 relative pt-20 pb-6 md:pt-20 bg-skeletonContainer">
			<div className="mb-12 md:mb-20">
				<Skeleton className="mb-2 bg-slate-300 w-3/4" height="32px"></Skeleton>
				<Skeleton className="mb-2 bg-slate-300 w-3/5" height="32px"></Skeleton>
			</div>
			<div className="grid gap-4 grid-rows-1 md:grid-cols-2 items-center">
				<div>
					<div className="mb-6">
						<Skeleton className="mb-2 bg-slate-300 w-full" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-3/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-3/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
					</div>
					<div className="mb-6">
						<Skeleton className="mb-2 bg-slate-300 w-full" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-3/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-3/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-full" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-3/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-3/5" height="32px"></Skeleton>
						<Skeleton className="mb-2 bg-slate-300 w-4/5" height="32px"></Skeleton>
					</div>
				</div>
				<div className="mb-10 text-center">
					<Skeleton className="mb-12 mx-auto bg-slate-300 w-4/5" height="500px"></Skeleton>
					<Skeleton className="mb-2 mx-auto bg-slate-300 w-48" height="48px"></Skeleton>
				</div>
			</div>
		</section>
	);
}

export default SkeletonGuide;
