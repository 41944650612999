import Skeleton from '../components/Skeleton';

function SkeletonLanding() {
	return (
		<section className="bg-skeletonContainer">
			<div className="container max-w-6xl xl:max-w-5xl 2xl:max-w-screen-lg mx-auto px-4 h-104 md:h-120">
				<div className="grid grid-cols-2 gap-2 justify-items-center justify-around pt-12 pb-10 md:pt-5 md:w-80 md:justify-items-start md:justify-start md:mb-8">
					<div className="m-4 grid">
						<Skeleton className="mb-2 bg-slate-300" height="18px"></Skeleton>
						<Skeleton className="w-24 m-auto md:w-28 md:m-0 bg-slate-300" height="58px"></Skeleton>
					</div>
					<div className="m-4 grid">
						<Skeleton className="mb-2 bg-slate-300" height="18px"></Skeleton>
						<Skeleton className="w-24 m-auto md:w-28 md:m-0 bg-slate-300" height="28px"></Skeleton>
					</div>
				</div>

				<div className="flex flex-col justify-center md:grid md:grid-rows-1 md:grid-cols-2 md:gap-4 md:justify-start md:items-center h-60 md:h-80">
					<div className="flex items-center justify-center md:flex-col md:items-start md:justify-start">
						<Skeleton className="w-12 h-24 md:w-24 md:h-40 mr-2 inline-block z-1 md:mb-4 bg-slate-300"></Skeleton>
						<Skeleton className="w-40 h-24 md:w-96 md:h-40 md:mb-4 bg-slate-300"></Skeleton>
						<Skeleton className="w-12 h-24 md:w-40 md:h-40 md:mb-4 bg-slate-300"></Skeleton>
					</div>
					<div>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
					</div>
				</div>
			</div>
			<div className="container py-10 max-w-6xl xl:max-w-5xl 2xl:max-w-screen-lg mx-auto px-4 bg-skeletonContainer">
				<Skeleton className="w-60 mx-auto mb-10 bg-slate-300" height="24px"></Skeleton>
				<div className="grid grid-flow-row gap-x-4 gap-y-10 md:grid-flow-col md:grid-cols-3">
					<div className="md:px-2">
						<Skeleton className="w-44 px-4 mb-10 mx-auto bg-slate-300" height="150px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
					</div>
					<div className="md:px-2">
						<Skeleton className="w-44 px-4 mb-10 mx-auto bg-slate-300" height="150px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
					</div>
					<div className="md:px-2">
						<Skeleton className="w-44 px-4 mb-10 mx-auto bg-slate-300" height="150px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
						<Skeleton className="w-full mb-3 bg-slate-300" height="12px"></Skeleton>
					</div>
				</div>
			</div>
			<div className="container max-w-6xl xl:max-w-5xl 2xl:max-w-screen-lg mx-auto px-4">
				<div className="grid grid-cols-2 gap-2 justify-items-center justify-around pt-12 pb-10 md:pt-5 md:w-80 md:justify-items-start md:justify-start md:mb-8">
					<div className="m-4 grid">
						<Skeleton className="mb-2 bg-slate-300" height="18px"></Skeleton>
						<Skeleton className="w-24 m-auto md:w-28 md:m-0 bg-slate-300" height="58px"></Skeleton>
					</div>
					<div className="m-4 grid">
						<Skeleton className="mb-2 bg-slate-300" height="18px"></Skeleton>
						<Skeleton className="w-24 m-auto md:w-28 md:m-0 bg-slate-300" height="28px"></Skeleton>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SkeletonLanding;
