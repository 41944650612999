import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

function Colaboration({ isHeader }: { isHeader?: boolean }) {
	const isConsent = getCookieConsentValue('reactiva-user');
	const alignItems = isHeader ? 'items-center' : '';

	const onGAClick = (which: string) => {
		if (isConsent) {
			ReactGA.event('Logos', {
				action: 'Visit logo page',
				label: which,
			});
		}
	};
	return (
		<div className="container py-0 max-w-6xl xl:max-w-6xl 2xl:max-w-screen-xl mx-auto px-4 pt-16 pb-10 md:pt-5">
			<div
				className={`grid grid-cols-2 gap-2 justify-items-center justify-around py-4 md:w-80 md:justify-items-start md:justify-start ${alignItems}`}>
				<div className="z-1 grid">
					<a
						href="https://www.ukpact.co.uk/country-programme/mexico"
						target="_blank"
						rel="noreferrer"
						className="no-underline"
						onClick={() => onGAClick('ukpact')}>
						{!isHeader && <p className="mb-2">Con el apoyo de:</p>}
						<img
							src="/assets/logo_ukpact_mexico.svg"
							alt="logo ukpact-mexico"
							className="w-24 m-auto md:w-28 md:m-0"
							width="112"
							height="73"
							loading="lazy"
						/>
					</a>
				</div>
				<div className="z-1 grid">
					<a
						href="https://bikencity.com/"
						target="_blank"
						rel="noreferrer"
						className="no-underline"
						onClick={() => onGAClick('bikencity')}>
						{!isHeader && <p className="mb-2">Desarrollado por:</p>}
						<img
							src="/assets/logo_bikencity.svg"
							alt="logo bikencity mexico"
							className="w-24 mx-auto md:w-28 md:m-0"
							width="112"
							height="22"
							loading="lazy"
						/>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Colaboration;
