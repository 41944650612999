import { gql } from '@apollo/client';

export const GET_POSTS = gql`
	query GetPosts($stage: Stage!) {
		posts(orderBy: date_DESC, stage: $stage) {
			title
			slug
			id
			date
			coverImage {
				__typename
				... on Asset {
					id
					size_180_jpg: url(
						transformation: {
							image: { resize: { width: 180, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					id
					size_380_jpg: url(
						transformation: {
							image: { resize: { width: 380, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_512_jpg: url(
						transformation: {
							image: { resize: { width: 512, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_720_jpg: url(
						transformation: {
							image: { resize: { width: 720, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_992_jpg: url(
						transformation: {
							image: { resize: { width: 992, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_1280_jpg: url(
						transformation: {
							image: { resize: { width: 1280, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
			}
			authorsPost {
				... on Author {
					id
					name
					picture {
						url
					}
				}
			}
		}
	}
`;

export const GET_POST = gql`
	query GetPost($slug: String, $stage: Stage!) {
		post(where: { slug: $slug }, stage: $stage) {
			title
			slug
			id
			date
			coverImage {
				__typename
				... on Asset {
					id
					size_180_jpg: url(
						transformation: {
							image: { resize: { width: 180, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					id
					size_380_jpg: url(
						transformation: {
							image: { resize: { width: 380, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_512_jpg: url(
						transformation: {
							image: { resize: { width: 512, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_720_jpg: url(
						transformation: {
							image: { resize: { width: 720, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_992_jpg: url(
						transformation: {
							image: { resize: { width: 992, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_1280_jpg: url(
						transformation: {
							image: { resize: { width: 1280, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
			}
			authorCover
			content {
				raw
			}
			authorsPost {
				... on Author {
					id
					name
					biography
					picture {
						url
					}
				}
			}
			seo {
				title
				keywords
				description
				facebookImage {
					id
					url
				}
				twitterImage {
					id
					url
				}
			}
		}
	}
`;

export const GET_CU = gql`
	query GetCu($stage: Stage!) {
		consultorioUrbano(stage: $stage, where: { id: "ckq4mv0kwej1v0a760mee4gb9" }) {
			tituloUno
			descripcionTituloUno
			tituloDos
			usuarioUno
			descripcionUsuarioUno {
				text
			}
			usuarioDos
			descripcionUsuarioDos {
				text
			}
			usuarioPaciente
		}
		tematica {
			tematica
			fechaTematica
			horaTematica
			descripcionTematica {
				html
			}
			fechaRegistroAprendiz
			fechaRegistroPaciente
			notaImportante
			tituloColaborador {
				html
			}
			logoColaborador {
				title
				alt
				link
				url(
					transformation: {
						image: { resize: { width: 512, fit: crop } }
						document: { output: { format: png } }
					}
				)
			}
			redesSocialesColaborador
			registroAbierto
			mensajeRegistroNoAbierto
		}
	}
`;

export const GET_GUIDE = gql`
	query GetGuide($stage: Stage!) {
		guiaDeGestion(stage: $stage, where: { id: "ckqctiagowszr0a79ajd52erq" }) {
			titulo
			contenido {
				html
			}
			cover {
				url(transformation: { image: { resize: { fit: scale, width: 720 } } })
			}
			documento {
				url
			}
			disclaimer
		}
	}
`;

export const GET_SUPPLIER_CATALOG = gql`
	query SupplierCatalog($stage: Stage!) {
		catalogoProveedor(stage: $stage, where: { id: "ckre7pok8rmpe0d84vx0ipysw" }) {
			titulo
			subtitulo
			bannerCatalogoProveedores {
				... on Asset {
					id
					size_380_jpg: url(
						transformation: {
							image: { resize: { width: 380, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				... on Asset {
					size_512_jpg: url(
						transformation: {
							image: { resize: { width: 512, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				... on Asset {
					size_720_jpg: url(
						transformation: {
							image: { resize: { width: 720, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				... on Asset {
					size_992_jpg: url(
						transformation: {
							image: { resize: { width: 992, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				... on Asset {
					size_1280_jpg: url(
						transformation: {
							image: { resize: { width: 1280, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
			}
			tituloBeneficios
			beneficios {
				html
			}
			tituloCategorias
			categoriasProveedores
			imagenesCategoriasProveedores {
				id
				url
				alt
				title
			}
			tituloTipoProveedor
			tipoProveedor
			tituloProveedor
			contenidoTipoProveedor {
				html
			}
			tituloTipoParticipacion
			tipoParticipacion
			tituloFechas
			contenidoFechas {
				html
			}
		}
		basesProveedor(where: { id: "ckrlhg7zcjlb60b27u52ftrxz" }, stage: $stage) {
			introduccion {
				html
			}
			tituloTipoProveedores
			contenidoTipoProveedores {
				html
			}
			tituloRequisitos
			contenidoRequisitos {
				html
			}
			tituloCalendario
			contenidoCalendario {
				html
			}
			tituloInformacion
			contenidoInformacion {
				html
			}
			tituloSeleccion
			contenidoSeleccion {
				html
			}
			tituloAvisoPrivacidad
			contenidoAvisoPrivacidad {
				html
			}
		}
	}
`;

export const GET_FINAL_REPORT_RESULTS = gql`
	query getFinalReportResults($stage: Stage!) {
		reporteFinalResultado(where: { id: "cl0oliq1t5l7i0dk66xcjop5p" }, stage: $stage) {
			titulo
			descripcion
			textoFinal
			disclaimer
			resultados {
				titleButton
				publication {
					url
					fileName
				}
			}
		}
	}
`;

export const GET_FINAL_REPORT = gql`
	query getFinalReport($stage: Stage!) {
		reporteFinales(stage: $stage) {
			id
			imagenCiudad {
				id
				url
				fileName
			}
			ciudad
			estado
			nombreDelProyecto
			longitud
			toneladasEvitadas
			estatus
			fichaTecnica {
				id
				url
				fileName
			}
			diagnostico {
				id
				url
				fileName
			}
			location {
				latitude
				longitude
			}
		}
	}
`;

export const GET_CITY = gql`
	query City($stage: Stage!) {
		ciudad(where: { id: "cl7aw6c9zl4er0eimmliro898" }, stage: $stage) {
			id
			titulo
			subtitle
			content {
				raw
			}
			image {
				fileName
				url
			}
		}
		postCiudades {
			id
			ciudad
			slug
			bannerMini {
				alt
				id
				url
			}
		}
	}
`;

export const GET_CITY_POST = gql`
	query CityPost($slug: String!, $stage: Stage!) {
		postCiudad(where: { slug: $slug }, stage: $stage) {
			titulo
			bannerGraphic {
				__typename
				... on Asset {
					size_180_jpg: url(
						transformation: {
							image: { resize: { width: 180, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_380_jpg: url(
						transformation: {
							image: { resize: { width: 380, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_512_jpg: url(
						transformation: {
							image: { resize: { width: 512, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_720_jpg: url(
						transformation: {
							image: { resize: { width: 720, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_992_jpg: url(
						transformation: {
							image: { resize: { width: 992, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
				__typename
				... on Asset {
					size_1280_jpg: url(
						transformation: {
							image: { resize: { width: 1280, fit: crop } }
							document: { output: { format: jpg } }
						}
					)
				}
			}
			content {
				raw
			}
			seo {
				title
				description
				keywords
			}
		}
	}
`;
